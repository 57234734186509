.alert {
    background-color: #a42134; /* Dark blue background */
    color: white; /* White text */
    padding: 5px 5px;
    text-align: center;
    position: static; /* Default, so it sits above the Navbar */
    width: 100%;
    z-index: 1000;
    font-family: "termina";
    font-size: 20px;
    display: flex; /* Align close button */
    justify-content: center; /* Center the content */
    align-items: center; /* Vertically align content */
    gap: 10px; /* Add spacing between the text and close button */
  }
  
  .alert-close {
    background: none;
    border: none;
    color: white;
    font-size: 25px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .alert-close:hover {
    color: #E8D8C4; /* Red on hover */
  }

  @media (max-width: 480px) {
    .alert {
      font-size: 10px;
    }
  }
  