/* Title Section */
.team-title-section {
    position: relative;
    width: 100%;
    height: 25vh;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-image: url(../../assets/teamBanner.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.team-title-text {
    font-size: 2.5rem;
    color: #fff;
    text-align: center;
    z-index: 1;
}

/* Main Section */
.team-main-section {
    padding: 60px 20px;
    background-color: #f9f9f9;
    color: #201E4B;
    font-family: 'Arial', sans-serif;
    text-align: center;
}

/* Team Members */
.team-members ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.team-members h3 {
    padding-top: 30px;
    padding-bottom: 20px;
    font-size: 2.0rem;
    color: #201E4B;
}

.team-member-profile {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 40px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 20px;
}

.team-member-left {
    flex: 1;
    max-width: 200px;
}

.team-member-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
}

.team-member-right {
    flex: 2;
    padding-left: 20px;
    text-align: left;
}

.team-member-right h4 {
    font-size: 1.2rem;
    color: #201E4B;
    margin-bottom: 10px;
}

.team-member-title {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 15px;
    font-style: italic;
}

.team-member-description {
    font-size: 1rem;
    color: #777;
    margin-bottom: 20px;
    line-height: 1.5;
}

.key-competencies {
    margin-top: 20px;
}

.key-competencies h5 {
    font-size: 1rem;
    color: #201E4B;
    margin-bottom: 10px;
}

.key-competencies ul {
    padding-left: 20px;
    list-style: disc;
    color: #555;
}

.key-competencies ul li {
    font-size: 1rem;
    margin-bottom: 5px;
    line-height: 1.4;
}

.team-closing {
    margin-top: 40px;
    font-size: 1.1rem;
    color: #201E4B;
}

/* Responsive Styles */
/* Responsive Styles */
@media (max-width: 1024px) {
    .team-member-profile {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center-align all items horizontally */
        text-align: center; /* Ensure the text content is centered */
    }

    .team-member-left {
        max-width: 100%; /* Allow the image container to use the full width */
        text-align: center; /* Center-align the image */
        margin-bottom: 20px; /* Add spacing between image and text */
    }

    .team-member-image {
        width: 100%; /* Ensure the image spans the full container width */
        max-width: 300px; /* Add a limit to prevent excessive stretching */
        height: auto; /* Maintain aspect ratio */
        object-fit: cover; /* Ensure the image scales properly */
        margin: 0 auto; /* Center the image horizontally */
        display: block; /* Block-level element for centering */
    }

    .team-member-right {
        padding-left: 0; /* Remove padding to center text */
        text-align: center; /* Center-align all text content */
    }

    .team-member-right h4 {
        font-size: 1.2rem; /* Adjust font size for headings */
        margin-bottom: 10px; /* Add spacing below the name */
    }

    .key-competencies ul {
        padding-left: 0; /* Remove unnecessary padding */
        text-align: left; /* Align bullet points to the left */
        display: inline-block; /* Ensure alignment within the centered content */
        margin: 0 auto; /* Center the competencies section */
    }
}


@media (max-width: 768px) {
    .team-member-profile {
        flex-direction: column;
        text-align: center;
    }

    .team-member-right {
        padding-left: 0;
    }
}

@media (max-width: 480px) {
    .team-member-right h4 {
        font-size: 1.2rem;
    }

    .team-member-title {
        font-size: 1rem;
    }

    .team-member-description {
        font-size: 0.95rem;
    }

    .key-competencies h5 {
        font-size: 1rem;
    }

    .key-competencies ul li {
        font-size: 0.9rem;
    }
}
