/* Title Section */
.about-title-section {
    position: relative;
    width: 100%;
    height: 30vh; /* Slightly taller for a grander look */
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-image: url(../../assets/aboutBanner.jpg); /* Replace with your banner */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    margin-bottom: 50px;
}

.about-title-text {
    position: relative;
    font-size: 2.8rem;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Add depth */
    text-align: center;
    z-index: 1;
}

.about-title-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Stronger overlay */
    z-index: 0;
}

/* Main Section */
.about-main-section {
    padding: 50px 20px;
    background-color: #f7f7f7;
    font-family: 'Arial', sans-serif;
    color: #333;
    text-align: center;
}

/* Content Container */
.about-content-container {
    max-width: 1000px;
    margin: 0 auto;
    background: white; /* Add a card-like effect */
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Intro Section */
.about-intro {
    font-size: 1.3rem;
    color: #555;
    line-height: 1.8;
    margin-bottom: 40px;
    text-align: justify;
}

/* Headings */
.about-mission h3,
.about-expertise h3,
.about-values h3 {
    font-size: 1.8rem;
    color: #A42134;
    margin-bottom: 20px;
    position: relative;
}

.about-mission h3::after,
.about-expertise h3::after,
.about-values h3::after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 3px;
    background-color: #201E4B; /* Accent color */
}

/* Paragraphs and Lists */
.about-mission p,
.about-expertise ul,
.about-values ul {
    font-size: 1.1rem;
    line-height: 1.8;
    max-width: 800px;
    margin: 0 auto 30px;
    text-align: justify;
}

.about-expertise ul,
.about-values ul {
    list-style: none;
    padding: 0;
}

.about-expertise ul li,
.about-values ul li {
    font-size: 1.1rem;
    margin-bottom: 15px;
    color: #555;
    line-height: 1.8;
}

.about-values ul li strong {
    color: #A42134;
    font-weight: bold;
}

.about-values p {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 15px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .about-title-text {
        font-size: 2rem;
    }

    .about-intro {
        font-size: 1.1rem;
    }

    .about-mission p,
    .about-expertise ul li,
    .about-values ul li {
        font-size: 1rem;
    }

    .about-content-container {
        padding: 20px;
    }
}

@media (max-width: 480px) {
    .about-title-text {
        font-size: 1.8rem;
    }

    .about-main-section {
        padding: 30px 15px;
    }

    .about-intro {
        font-size: 1rem;
    }
}


/* Fix for About Page Text on Small Screens */
@media (max-width: 425px) {
    /* General fixes for the About Section */
    .about-main-section {
      padding: 10px; /* Reduce overall padding */
    }
  
    .about-content-container {
      display: flex;
      flex-direction: column; /* Stack the content vertically */
      gap: 20px; /* Add consistent spacing between sections */
    }
  
    .about-mission,
    .about-expertise,
    .about-values {
      padding: 10px; /* Reduce padding around each section */
      margin: 0; /* Remove unnecessary margins */
    }
  
    .about-mission h3,
    .about-expertise h3,
    .about-values h3 {/* Reduce heading size */
      margin-bottom: 10px; /* Add consistent spacing below headings */
      text-align: center; /* Center align headings */
    }
  
    .about-mission p{
      text-align: center;
    }
  
    .about-values p,
    .about-expertise ul li,
    .about-values ul li {
      line-height: 1.5; /* Ensure consistent line spacing */
      margin-bottom: 10px; /* Add spacing between paragraphs */
      text-align: left; /* Justify text for a clean layout */
    }
  }  