/* General fix for white line issue */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Maintain existing laptop/desktop styles (unchanged) */
/* Main Section Styles */
.main-section {
  position: relative;
  width: 100%;
  height: 85vh;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-image: url(../../assets/bannerImage2.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.banner-text {
  position: relative;
  font-size: 2.5rem;
  font-weight: normal;
  color: #ffffffff;
  text-align: center;
  z-index: 1;
}

.main-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 0;
}

/* Learn more button */
.learn-more-button {
  display: inline-block;
  margin-top: 20px;
  font-size: 1.1rem;
  color: #201E4B;
  text-decoration: none;
  border: 2px solid #201E4B;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: transparent;
  transition: background-color 0.3s, color 0.3s;
}

.learn-more-button:hover {
  background-color: #201E4B;
  color: #fff;
}

/* Services Section Styles */
.services-section {
  height: 50vh;
  padding: 40px;
  background-color: #f0f0f0;
  text-align: center;
}

.services-section h3 {
  font-size: 1.8rem;
  color: #333;
}

.services-section ul {
  list-style-type: none;
  padding: 0;
}

.services-section li {
  margin: 10px 0;
  font-size: 1rem;
  color: #555;
}

/* About Section Styles */
.about-section {
  padding: 40px;
  background-color: #f0f0f0;
  /* height: 50vh; */
  text-align: center;
}

.about-details {
  padding: 40px;
  background-color: #f0f0f0;
  /* height: 50vh; */
  text-align: center;
}

.about-section h3 {
  font-size: 1.8rem;
  color: #333;
}

.about-section p {
  font-size: 1.5rem;
  line-height: 1.6;
  color: #000;
}

.about-details p {
  font-size: 1.5rem;
  line-height: 1.6;
  color: #000;
}

/* Team Section Styles */
.team-section {
  /* height: 50vh; */
  padding: 35px;
  background-color: #f0f0f0;
  text-align: center;
  font-size: 1.5rem;
}

.team-section h3 {
  font-size: 1.8rem;
  color: #333;
}

/* Ribbon Section Styles */
.ribbon-section {
  position: relative;
  width: 100%;
  height: 37.5vh;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-image: url(../../assets/Ribbon.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.ribbon-text {
  position: relative;
  font-size: 1.8rem;
  font-weight: normal;
  color: #ffffff;
  text-align: center;
  z-index: 1;
}

.ribbon-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 0;
}

/* Contact Section Styles */
.contact-section {
  padding: 40px;
  background-color: #f8f9fa;
  text-align: center;
}

.contact-section h3 {
  font-size: 1.8rem;
  color: #333;
}

.contact-section form {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-section input,
.contact-section textarea,
.contact-section select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-section button {
  padding: 10px;
  background-color: #201E4B;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contact-section button:hover {
  background-color: #2a2849;
}

/* Responsive styles for mobile devices */
@media (max-width: 768px) {
  /* Main Section */
  .main-section {
    height: 75vh; /* Retain the same height as desktop */
    padding: 0; /* Keep padding consistent with desktop */
    background-attachment: fixed; /* Optimize performance for mobile */
    position: relative;
    background-position: center fixed;
  }

  .banner-text {
    font-size: 1.8rem; /* Reduce font size */
    padding: 0 10px; /* Add horizontal padding */
  }

  /* Services Section */
  .services-section {
    padding: 20px; /* Adjust padding */
    height: auto; /* Let height adjust dynamically */
  }

  .services-section h3 {
    font-size: 1.5rem; /* Reduce heading size */
  }

  .services-section ul {
    padding: 0; /* Adjust list padding */
    margin: 0 auto; /* Center align content */
  }

  .services-section li {
    font-size: 0.9rem; /* Slightly reduce text size */
    line-height: 1.4; /* Add line spacing */
  }

  /* About Section */
  .about-section {
    padding: 20px;
    height: auto; /* Let the height adjust dynamically */
  }

  .about-section h3 {
    font-size: 1.5rem; /* Reduce heading size */
  }

  .about-section p {
    font-size: 0.9rem; /* Reduce font size for better fit */
    line-height: 1.4; /* Improve readability */
  }

  /* Team Section */
  .team-section {
    padding: 20px;
    height: auto; /* Allow height to adjust dynamically */
  }

  .team-section h3 {
    font-size: 1.5rem; /* Reduce heading size */
  }

  .team-section p {
    font-size: 0.9rem; /* Adjust font size */
    line-height: 1.4; /* Improve spacing */
  }

  /* Ribbon Section */
  .ribbon-section {
    height: 37.5vh; /* Allow height to adjust */
    padding: 20px; /* Add padding for readability */
    background-attachment: fixed; /* Disable fixed background */
  }

  .ribbon-text {
    font-size: 1.4rem; /* Adjust text size for smaller screens */
    padding: 0 10px; /* Add padding */
  }

  /* Contact Section */
  .contact-section {
    padding: 20px;
  }

  .contact-section h3 {
    font-size: 1.5rem;
  }

  .contact-section form {
    gap: 10px; /* Adjust gap for smaller screens */
  }

  .contact-section input,
  .contact-section textarea,
  .contact-section select {
    font-size: 0.9rem; /* Slightly reduce font size */
    padding: 8px; /* Adjust padding */
  }

  .contact-section button {
    font-size: 0.9rem;
    padding: 8px;
  }
}

/* Adjustments for very small screens */
@media (max-width: 480px) {
  /* Navbar */
  .App-header {
    padding: 15px 10px; /* Reduce padding for smaller navbar */
  }

  .logo {
    font-size: 20px; /* Reduce logo font size for better fit */
  }


  .hamburger {
    font-size: 20px; /* Adjust hamburger size */
  }

  /* Main Section */
  .main-section {
    padding: 10px; /* Reduce padding around the section */
    height: 500px; /* Slightly reduce height for better fit */
  }

  .banner-text {
    font-size: 1.5rem; /* Reduce text size for better fit */
    line-height: 1.2; /* Adjust line spacing */
  }

  /* Services Section */
  .services-section {
    padding: 15px; /* Reduce padding for smaller screens */
  }

  .services-section h3 {
    font-size: 1.2rem; /* Reduce heading size */
  }

  .services-section li {
    font-size: 0.8rem; /* Reduce text size */
    line-height: 1.3; /* Adjust spacing */
  }

  /* About Section */
  .about-section {
    padding: 15px; /* Reduce padding */
  }

  .about-section h3 {
    font-size: 1.2rem; /* Adjust heading size */
  }

  .about-section p {
    font-size: 0.9rem; /* Reduce paragraph font size */
    line-height: 1.4; /* Adjust line spacing */
  }

  /* Team Section */
  .team-section {
    padding: 15px; /* Reduce padding */
  }

  .team-section h3 {
    font-size: 1.2rem; /* Adjust heading size */
  }

  .team-section p {
    font-size: 0.8rem; /* Reduce paragraph font size */
    line-height: 1.3; /* Adjust line spacing */
  }

  /* Ribbon Section */
  .ribbon-section {
    height: 30vh; /* Reduce ribbon height */
    padding: 10px; /* Add padding for readability */
  }

  .ribbon-text {
    font-size: 1.2rem; /* Reduce text size */
    padding: 0 10px; /* Add padding */
  }

  /* Contact Section */
  .contact-section {
    padding: 15px; /* Reduce padding */
  }

  .contact-section h3 {
    font-size: 1.2rem; /* Adjust heading size */
  }

  .contact-section form {
    gap: 8px; /* Reduce gap between inputs */
  }

  .contact-section input,
  .contact-section textarea,
  .contact-section select {
    font-size: 0.8rem; /* Reduce font size */
    padding: 8px; /* Adjust padding */
  }

  .contact-section button {
    font-size: 0.8rem; /* Reduce button text size */
    padding: 8px; /* Adjust button padding */
  }
}

/* Fallback for Mobile Devices */
@supports (-webkit-overflow-scrolling: touch) {
  .main-section,
  .ribbon-section {
    background-attachment: scroll; /* Fallback for devices like iPhones */
  }
}

/* Adjustments for Mobile and Small Screens */
@media (max-width: 768px) {
  .main-section {
    height: 75vh; /* Adjust height for smaller screens */
    background-position: center; /* Ensure centered position */
  }

  .ribbon-section {
    height: 37.5vh; /* Adjust height for smaller screens */
    background-position: center; /* Ensure centered position */
  }

  .banner-text,
  .ribbon-text {
    font-size: 1.8rem;
    padding: 0 10px; /* Add horizontal padding */
  }
}

@media (max-width: 480px) {
  .main-section {
    height: 500px; /* Further adjust height for very small screens */
  }

  .ribbon-section {
    height: 30vh; /* Reduce height for compact screens */
  }

  .banner-text,
  .ribbon-text {
    font-size: 1.5rem; /* Reduce font size for better fit */
    line-height: 1.2; /* Adjust line spacing */
  }
}

