/* General styles for header */
.App-header {
  background-color: #000;
  padding: 20px;
  display: flex;
  flex-direction: row; /* Align logo and navigation in a row */
  align-items: flex-start; /* Align items at the top */
  justify-content: space-between; /* Space between logo and navigation */
  flex-wrap: nowrap; /* Prevent wrapping of elements */
  position: relative; /* Keep the Navbar's default position */
  top: 0; /* Default top position */
  width: 100%;
  z-index: 900;
  transition: margin-top 0.3s ease; /* Smooth adjustment when Alert is dismissed */
}

/* Logo container styling */
.logo-container {
  display: flex;
  flex-direction: row; /* Logo image and text are side by side */
  align-items: flex-start; /* Align logo and text at the top */
  justify-content: flex-start; /* Align everything to the left */
  cursor: pointer; /* Make the entire container clickable */
  padding: 0 10px; /* Add spacing for alignment */
}

/* Logo image styling */
.logo-image {
  width: 105px; /* Adjust the size of the logo image */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Space between the logo image and text */
}

/* Logo text styling */
.logo-text {
  display: flex;
  flex-direction: column; /* Stack logo text and tagline vertically */
  align-items: flex-start; /* Align text to the left */
  text-align: left; /* Ensure text alignment is left */
}

.logo-text:hover .logo,
.logo-text:hover .tagline {
  color: #E8D8C4; /* Change color of both logo and tagline on hover */
}

.logo {
  font-family: "termina";
  font-size: 28px; /* Slightly larger font size for the logo */
  font-weight: bold;
  color: #a42134;
  line-height: 1.2; /* Adjust spacing between lines */
}

.logo .line1,
.logo .line2,
.logo .line3 {
  display: block; /* Ensure each line behaves as a block */
}

/* Tagline styling */
.tagline {
  font-family: "termina";
  font-size: 18px; /* Slightly smaller font size than the logo */
  color: #a42134; /* Complementary color to the logo */
  margin-top: 5px; /* Add spacing between logo text and tagline */
}

/* Navigation menu */
.navigation {
  display: flex;
  flex-direction: row; /* Ensure links are side-by-side */
  justify-content: flex-end; /* Align navigation links to the right */
  gap: 65px; /* Add spacing between navigation links */
  margin-top: auto; /* Push navigation towards the bottom */
  margin-right: 20px;
  align-self: flex-end; /* Align the navigation to the bottom of the header */
}

/* Navigation buttons */
.navigation button {
  background: none; /* Remove default button background */
  border: none; /* Remove button border */
  margin: 0; /* Remove unnecessary margins */
  text-decoration: none;
  color: #a42134;
  font-family: "termina";
  font-size: 18px; /* Adjust font size for readability */
  cursor: pointer; /* Pointer cursor on hover */
}

.navigation button:hover {
  color: #E8D8C4; /* Match the hover effect with the logo */
}

/* Hamburger menu for small screens */
.hamburger {
  font-size: 24px;
  background: none;
  border: none;
  color: #E8D8C4;
  cursor: pointer;
  display: none; /* Hidden by default */
  z-index: 1001;
}

/* Mobile menu overlay */
.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: none; /* Hidden by default */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}

.menu-overlay.open {
  display: flex; /* Display overlay when open */
  transform: translateY(0);
}

/* Mobile menu links */
.menu-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.menu-links button {
  background: none;
  border: none;
  font-size: 18px;
  color: #E8D8C4;
  text-transform: uppercase;
  font-family: "termina";
  cursor: pointer;
  padding: 10px 20px;
  transition: background-color 0.3s, color 0.3s;
}

.menu-links button:hover {
  background-color: #E8D8C4;
  color: #A42134;
  border-radius: 5px;
}

/* Responsive styles for screens smaller than 950px */
@media (max-width: 1020px) {
  .hamburger {
    display: block; /* Show hamburger menu on small screens */
  }

  .navigation {
    display: none; /* Hide desktop menu on small screens */
  }
}

@media (max-width: 1200px) {
  .logo-image {
    width: 90px; /* Adjust the size of the logo image */
  }

  .logo {
    font-size: 24px;
  }

  .tagline {
    font-size: 14px;
  }

  .navigation{
    gap: 40px;
    margin-right: 10px;
  }

  .navigation button {
    font-size: 14px;
  }
}

@media (max-width: 1350px) {
  .navigation{
    gap: 40px;
    margin-right: 10px;
  }

}

@media(max-width: 480px){
  .logo-image {
    width: 75px; /* Adjust the size of the logo image */
  }
}
