/* General Container */
.rcm-container {
    font-family: 'Roboto', Arial, sans-serif;
    color: #333;
    line-height: 1.7;
    background-color: #f7f7f7;
    margin: 0;
    padding: 0;
}

/* Hero Section */
.rcm-hero {
    background: url('./../../assets/Ribbon.jpg') no-repeat center center/cover;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
    text-align: center;
    background-attachment: fixed;
}

.hero-overlay {
    background: rgba(0, 0, 0, 0.6);
    padding: 30px;
    border-radius: 12px;
    max-width: 90%;
}

.rcm-hero h1 {
    font-size: 3.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.rcm-hero p {
    font-size: 1.3rem;
    margin-top: 10px;
    opacity: 0.9;
}

/* Expansion Sections */
.rcm-expansion-sections {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 50px 20px;
    background-color: #fff;
    align-items: flex-start; /* Align items at the top to prevent stretching */
}

.expansion-section {
    flex: 1 1 calc(33.333% - 20px);
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease, height 0.3s ease;
    text-align: center;
    overflow: hidden;
    height: auto; /* Let the height be controlled dynamically by the content */
    min-height: 120px; /* Add a consistent base height for collapsed sections */
}

.expansion-section.expanded {
    height: auto; /* Allow expanded section to grow naturally */
}

.expansion-section:not(.expanded) {
    height: 120px; /* Fix the height of non-expanded sections */
}

.expansion-section h3 {
    font-size: 1.5rem;
    color: #a42134;
    margin-bottom: 10px;
}

.expansion-section .expanded-content {
    display: none; /* Default: hidden */
    margin-top: 10px;
    text-align: left;
}

.expansion-section.expanded .expanded-content {
    display: block; /* Display content for expanded section */
}

.expansion-section:hover {
    transform: translateY(-5px);
}

/* Value Addition Section */
.rcm-value-addition {
    background-color: #f9f9f9;
    padding: 50px 20px;
    text-align: center;
}

.rcm-value-addition h2 {
    font-size: 2rem;
    color: #a42134;
    margin-bottom: 20px;
    text-align: center; /* Center the title */
}

.rcm-value-addition ul {
    list-style: none;
    padding: 0;
    margin: 0 auto; /* Center the content horizontally */
    font-size: 1.2rem;
    color: #555;
    line-height: 2;
    max-width: 600px; /* Limit the width of the content for better alignment */
    text-align: left; /* Align the list items to the left */
}

.rcm-value-addition ul li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
}

.rcm-value-addition ul li::before {
    content: "✔";
    color: #a42134;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.2rem;
}

@media screen and (max-width: 425px) {
    /* Hero Section */
    .rcm-hero {
        height: 300px; /* Reduce height for smaller screens */
        background-attachment: scroll; /* Fix parallax issue on small screens */
    }

    .hero-overlay {
        padding: 20px;
        border-radius: 8px;
    }

    .rcm-hero h1 {
        font-size: 2rem; /* Adjust font size */
        margin-bottom: 8px;
    }

    .rcm-hero p {
        font-size: 1rem;
    }

    /* Expansion Sections */
    .rcm-expansion-sections {
        flex-direction: column; /* Stack sections vertically */
        gap: 15px;
        padding: 20px 10px;
    }

    .expansion-section {
        flex: none; /* Remove flex-basis calculation */
        width: 100%; /* Take full width */
        min-height: 100px; /* Adjust base height */
    }

    .expansion-section h3 {
        font-size: 1.2rem; /* Reduce heading size */
    }

    .expansion-section .expanded-content {
        font-size: 0.9rem; /* Adjust text size */
    }

    /* Value Addition Section */
    .rcm-value-addition {
        padding: 30px 10px;
    }

    .rcm-value-addition h2 {
        font-size: 1.5rem;
    }

    .rcm-value-addition ul {
        font-size: 1rem;
        max-width: 100%; /* Remove width limit */
    }

    .rcm-value-addition ul li {
        font-size: 0.9rem; /* Reduce font size for list items */
    }
}

@media(max-width: 320px){
    .rcm-hero h1 {
        font-size: 1.5rem;
    }
}