/* General App Styles */
.App {
  font-family: "termina";
  color: #000;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
