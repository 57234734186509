.scroll-to-top {
    position: fixed;
    right: 20px;
    width: 50px;
    height: 50px;
    background-image: url('../../assets/ScrollButton.png'); /* Use your SVG */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1000; /* Ensure it appears above other elements */
    transition: bottom 0.3s ease, transform 0.3s ease; /* Smooth position change */
}

.scroll-to-top:hover {
    transform: scale(1.1); /* Slightly enlarge on hover */
}